<!--大屏头部-->
<template>
  <div class="chengdu-header" v-if="isChengdu">
    <div style="width:100%;height:40rem;">
      <div style="float:left;height:40rem;display: flex;align-items: center;">
        <img @click="returnIndex" style="margin-left:8rem;z-index:10;width:40rem; " src="./img/EnterSys.png" alt="进入系统" />
        <span style="font-size:14rem;margin-left:12rem;">{{ currentTime.date }} {{currentTime.week}}</span> <span style="font-size:16px;margin-left:24px;">{{ currentTime.time }}</span>
      </div>
      <div style="float:right;height:40rem;display: flex;align-items: center;margin-right:32rem;">
        <span style="font-size: 16rem;">{{ user.Name }}</span><span style="margin-left: 20rem;font-size:12rem;">欢迎进入</span>
        <template v-if="!isFullScreen">
          <img @click="switchFullScreen" style="width:24rem;margin-left: 20rem;" src="./img/OpenFullScreen.png" alt="打开全屏">
        </template>
        <template v-else>
          <img @click="switchFullScreen" style="width:24rem;margin-left: 20rem;" src="./img/CloseFullScreen.png" alt="关闭全屏">
        </template>
      </div>
    </div>
    <div style="width:100%;text-align:center;margin-top:-24rem;">
      <span style="letter-spacing: 4rem;font-size:28rem;font-weight:550; text-shadow: 2px 4px 5px #fff;">{{ appName }}</span>
    </div>
  </div>
  <div v-else>
    <el-row class="top-header">
      <el-col v-if="user.Name!=='AJDP'" class="header-content header-left" :span="2" @click.native="returnIndex">
        <img style="margin-right:8px;z-index:10 " src="./img/EnterSys.png" alt="进入系统" />
        <span class="bs-font-nav">进入系统</span>
      </el-col>
      <el-col class="header-content header-left" :span="3">
        <select-city @citySelectChange="areaCodeChange" @on-change="selectCityChange"></select-city>
      </el-col>
      <el-col class="header-content header-center" :span="14">
        <span style="letter-spacing: 4px;" class="bs-font-top-title">{{ appName }}</span>
      </el-col>
      <el-col class="header-content header-right" :span="5">
        <div class="time_box">
          <div>
            <span><span style="font-size: 16px;">{{ user.Name }}</span>&nbsp&nbsp&nbsp欢迎进入</span>
            <span>{{ currentTime.date }} {{currentTime.week}} {{ currentTime.time }}</span>
          </div>
        </div>
        <div style="padding-top:8rem;" @click="switchFullScreen">
          <img width="32" src="./img/OpenFullScreen.png" alt="打开全屏" v-if="!isFullScreen">
          <img width="32" src="./img/CloseFullScreen.png" alt="关闭全屏" v-else>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment'
import selectCity from './city'
import conf from '@/config'
import {
  loadData,
  removeToken,
  getUserInfo
} from '@/util/index'

export default {
  components: {
    selectCity
  },
  name: 'TopHeader',
  data() {
    return {
      appName: null,
      isChengdu: false,
      currentTime: {
        week: '',
        time: '',
        date: ''
      },
      timer: null,
      user: {
        Name: ''
      },
      isFullScreen: false
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
    if (this.user.Info && this.user.Info.name && this.user.Info.name.indexOf("在线油烟监管") != -1 ) {
      this.appName = this.user.Info.name
    } else {
      this.appName = conf.appName
    }
  },
  mounted() {
    // 调用js的函数moment，初始化时间
    moment.locale('zh-cn')
    this.timer = setInterval(() => {
      this.currentTime.week = moment().format('dddd')//完整的星期几名称
      this.currentTime.time = moment().format('LTS')//格式为hh:mm:ss
      this.currentTime.date = moment().format('L')//短日期格式，即YYYY-MM-DD
    }, 1000)
  },
  beforeDestroy() {
    // this.timer = null
    clearInterval(this.timer)
  },
  methods: {
    //接受子组件传过来的方法
    areaCodeChange(param) {//选择城市
      //向父组件发送方法：cityChange，值：param
      this.$emit('cityChange', param)
    },

    selectCityChange(area, n) {
      this.curArea = Object.assign({}, area, this.contentData)
      this.$emit('cityChange', area)

    },
    switchFullScreen() {
      const app = document.getElementsByTagName('html')
      if (this.isFullScreen) {
        document.exitFullscreen()
        this.isFullScreen = false
      } else {
        app[0].requestFullscreen()
          .then(() => {
            this.isFullScreen = true
          })
          .catch((err) => {
            alert(err)
          })
      }
    },
    // 返回首页
    returnIndex() {
      loadData(this)
      this.$router.replace({path: '/Intime'})
    },

    /**
     * @description 退出系统
     */
    handleCommand(command) {
      if (command === 'logout') {
        this.$confirm('确认退出吗?', '提示', {}).then(res => {
          // this.$post('logoutAction').then(data => {})
          removeToken()

          this.$store.commit('clear')
          this.$router.push({
            name: 'login'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>


.chengdu-header{
  width: 100%;
  height: 100rem;
  box-sizing: border-box;
  background: no-repeat url('./img/header.png');
  background-size: 100% 100%;
}
.top-header {
  font-size: 20px;
  width: 100%;
  height: 128rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: no-repeat url('./img/BoardTopNav.png');
  background-size: 100% 100%;
  .header-content {
    display: flex;
    justify-content: space-around;
    display: flex;
    align-items: center;
    height: 80rem;
    padding-bottom:40rem;
  }

  .header-left {
    z-index: 99;
    left: 2%;
    cursor: pointer;
    justify-content: flex-start;
    color:#2ee3ff;
    padding-left:24rem;
    font-size:12px;
    i {
      margin-right: 12px;
    }
  }

  .header-center {
    text-align: center;
    left: 0;
    right: 0;
    margin:0 auto;
    font-size: 18px;
  }

  .header-right {
    opacity: 0.8;
    font-weight: 500;
    text-align: center;
    color: #fff;
    right: 2%;

    .time_box {
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }
    }
    .btn_enter_sys {
      font-size: 14px;

      span {
        line-height: 26px;
        display: flex;

        .iconfont {
          font-size: 26px;
          line-height: 1;
          margin-right: 8px;
        }
      }
    }

    .dropdown_admin_box {
      .fa {
        font-size: 26px;
        margin-right: 7px;
      }

      .el-dropdown-link {
        display: flex;
      }

      .el-dropdown-link,
      .el-icon-caret-bottom {
        line-height: 26px;
      }

      .el-icon-caret-bottom {
        margin-left: 10px;
      }
    }
  }
}
.dropdown_admin_menu {
  border-color: #3a8efd;
  border-radius: 3px;

  .el-dropdown-menu__item {
    color: #24FFFC;
  }

  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: rgba($color: #24FFFC, $alpha: 0.1);
    color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #03232A;
  }
}
</style>
