<!--大屏选择区域-->
<template>
  <div class="city_page">
    <div class="city_box">
      <el-cascader
        v-show="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        :options="cityData"
        :props="{
          value: 'Name',
          label: 'Name',
          checkStrictly: true
        }"
        popper-class="select_city_popper"
        @change="cityChange"
      ></el-cascader>
      <el-button v-show="!inputVisible" class="btn_add" @click="showInput">+</el-button>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {loadData, getUserInfo} from '@/util/index'
import { cityData } from '@/view/bigscreen/assets/util/index'

export default {
  name: "SelectCity",
  data() {
    return {
      userMobile: '', // 用户手机号，用于提取本地对应账号的存储值
      quickAreas: [], // 快捷区域数据集合
      inputVisible: false,
      inputValue: '',
      cityData: [] // 城市三级列表
    }
  },
  computed: {
    ...mapState({
      areaTree: (state) => state.areaTree
    })
  },
  mounted() {
    setTimeout(() => {
      if (this.areaTree && this.areaTree.length > 0) {
        this.cityData = this.areaTree[0].children
      } else {
        console.log("没有执行")
      }
    }, 5000);
    // 获取用户手机号
    this.userMobile = getUserInfo().Mobile

  },
  methods: {


    /**
     * @description 选择快捷区域
     * @param {Number} index 快捷区域所在索引值
     */
    handleClick(index) {
      this.$emit('on-change', index >= 0 ? this.quickAreas[index] : null, 1)
      this.quickAreas = this.quickAreas.map((item, i) => {
        if (index === i) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      })
    },
    /**
     * @description 显示添加快捷区域按钮
     */
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    /**
     * @description 选择区域
     * @param { Array } value 选择区域索引值
     */
    cityChange(value) {
      this.inputVisible = true
      this.inputValue = value
      var level = 'country'
      if (this.$refs.saveTagInput.getCheckedNodes()[0].data.Typ == 1) {
        level = 'province'
      } else if (this.$refs.saveTagInput.getCheckedNodes()[0].data.Typ == 2) {
        level = 'city'
      } else if (this.$refs.saveTagInput.getCheckedNodes()[0].data.Typ == 3) {
        level = 'district'
      } else {
        level = 'street'
      }
      var param = {
        code: this.$refs.saveTagInput.getCheckedNodes()[0].data.Code,
        level: level
      }
      //选择好城市后，向父组件发送方法：citySelectChange，值：param，
      this.$emit('citySelectChange', param)
    },
  },
}
</script>
<style lang="scss">
.city_page {
  height: 4vh;
  h5 {
    font-size: 11px;
    color: #B2F2F6;
    padding: 0 35px;
    font-weight: normal;
    margin: 0;
  }
}

.city_box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 5rem;
  .btn_add {
    height: 32px;
    border: 1px solid #24FFFC;
    width: 100px;
    border-radius: 0;
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    line-height: 32rem;
    color: #B2F2F6;
  }

  .el-cascader {
    margin: 0 0 8px 12px;
    line-height: initial;

    .el-input .el-input__inner:focus,
    .el-cascader .el-input.is-focus .el-input__inner {
      border-color: #B2F2F6;
    }

    .el-input .el-input__inner:focus,
    .el-input.is-focus .el-input__inner {
      border-color: #24FFFC;
    }

    .el-input__inner {
      border-radius: 0;
      background-color: transparent;
      height: 32px;
      font-size: 14px;
      color: #B2F2F6;
      border-color: #24FFFC;

      &:focus {
        border-color: #24FFFC;
      }
    }
  }
}

.select_city_popper {
  background: rgba(21, 55, 135, 0.8);
  border-color: #24FFFC;
  border-radius: 3px;

  .el-cascader-node.in-active-path,
  .el-cascader-node.is-active,
  .el-cascader-node.is-selectable.in-checked-path {
    color: #24FFFC;
  }

  .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
    background-color: rgba($color: #24FFFC, $alpha: 0.1);
  }

  .el-cascader-menu {
    color: rgba($color: #24FFFC, $alpha: 0.7);
    border-right-color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #03232A;
  }

  .el-radio__inner {
    background-color: transparent;
    border-color: #24FFFC;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #24FFFC;
    background-color: #24FFFC;
  }
}
</style>
